import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import * as htmlToImage from 'html-to-image';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { isAppleDevice } from 'src/app/helpers/utils';

declare global {
  interface Window {
    ApexCharts: any;
  }
}

@Component({
  selector: 'comment-button',
  templateUrl: './comment-button.component.html',
  styleUrls: ['./comment-button.component.scss']
})
export class CommentButtonComponent implements OnInit {
  @Input() type?: 'Comments' | 'SummaryRemarks' | 'OwnerComments' = 'Comments';
  @Input() title1: string;
  @Input() title2?: string;
  @Input() chartId: string;
  @Input() screen?: HTMLElement;
  @Input() chartRef?: ApexCharts;
  @Input() chatIdDependency?: any;
  @Input() users: any;
  @Output() beforeScreen = new EventEmitter();
  @Output() afterScreen = new EventEmitter();
  image = '';
  allComments: Object[];
  comments: any[];
  commentStatuses: any[] = [];
  username = this.getDisplayName(localStorage.getItem('email')) ?? ''
  newCommentText: string;
  editCommentId: string;
  editCommentText: string;
  isNonApexLoading: boolean = false;
  usermentions = [];
  receivers = [];
  emailbody = {};
  commentbody = {};
  updatecommentbody = {};
  deletecommentbody = {};
  ownerComment = 'Loading...';
  ownerCommentID = '';
  canRead: boolean = false;
  canWrite: boolean = false;
  focusLinkLoaded: boolean = false;
  isLoading: boolean = false;
  validationError: string = '';
  limitOwnerComment: number = 500;
  isModalOpen: boolean = true;
  modalOptions: NgbModalOptions = {
    windowClass: 'commentModal',
    backdrop: true
  };

  @ViewChild('commentModal', { static: true }) commentModal!: ElementRef;
  private modalRef: NgbModalRef | null = null;

  constructor(private router: Router, private http: HttpClient, private modalService: NgbModal) { }

  ngOnChanges(changes: OnChanges) {
    if (this.chatIdDependency?.[0]?.selectedDate || this.chatIdDependency?.[0]?.selectedWeek)
    {
      this.getSelfComments()
    }
  }

  ngOnInit() {
  }

  getChatId() {
    const base64Encoded = btoa(JSON.stringify(this.chatIdDependency))
    console.log(JSON.stringify(this.chatIdDependency))
    return `${this.chartId}[${base64Encoded}]`;
  }

  capitalizeEachWord(name: string) {
    return name.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  getDisplayName(email) {
    return this.capitalizeEachWord(email.split('@')[0].replaceAll('.', ' '));
  }

  getDisplayTime(time: string) {
    // TO-DO: human readable
    return time.split("T")[0];
  }

  wrapUsername(inputString: string) {
    const regex = /@[\w.]+/g;
    const outputString = inputString.replace(regex, function (match) {
      if (`@${localStorage.getItem('username')}` === match) {
        return `<span class="red">${match}</span>`;
      } else {
        return `<span class="blue">${match}</span>`;
      }
    });
    return outputString
  }

  removeHiddenCharacters(inputString: string) {
    return inputString.replace(/[\u200B-\u200D\uFEFF]/g, '');
  }

  getSelfComments() {
    this.getComments(this.getChatId());
  }

  updateOwnerComment() {
    document.getElementById(`ownerComments_${this.chartId}_OwnerComments`)?.dispatchEvent(new CustomEvent('refresh'));
    document.getElementById(`summaryRemarks_${this.chartId}_SummaryRemarks`)?.dispatchEvent(new CustomEvent('refresh'));
  }

  private getComments(chatId: string) {
    let queryUrl = environment.hygraphGetComment + "?chatId=" + chatId;
    let httpOptions: Object = {
    headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
    responseType: 'text'
    }
    this.http.get<any>(queryUrl, httpOptions)
    .subscribe(
        response => {
            let res = JSON.parse(response);
            if (res.data) {
              this.comments = res.data[environment.comment].map((msg) => {
                return {
                  ...msg,
                  message: this.wrapUsername(decodeURIComponent(msg.message)),
                  rawMessage: decodeURIComponent(msg.message),
                  shouldShowAll: this.commentStatuses.find(x => x.commentId == msg.id)?.shouldShowAll
                }
              });
            }

            const commentGroups = JSON.parse(localStorage.getItem("userinfo"))[environment.customer][0].commentGroups;
            const reportPermission = commentGroups.find(x => x.page.url === location.pathname);
            const canWrite = reportPermission?.canWrite;
            if (canWrite) {
              this.canRead = true;
              this.canWrite = true;
            } else {
              const canRead = reportPermission?.canRead;
              this.canRead = canRead;
            }

            // Set owner message
            const hasSelectedDateOrWeek = this.chatIdDependency?.[0]?.selectedDate || this.chatIdDependency?.[0]?.selectedWeek
            if (this.type === 'OwnerComments' && hasSelectedDateOrWeek) {
              let owners = this.users.filter(x => x.commentGroups.some(y => y.isOwner));
              const firstCommentFromOwners   = this.comments?.find(x => owners.some(y => y.email == x.email))?.message;
              const firstCommentFromOwnersID = this.comments?.find(x => owners.some(y => y.email == x.email))?.id;

              if (this.canRead) {
                if (firstCommentFromOwners) {
                  this.ownerComment   = firstCommentFromOwners;
                  this.ownerCommentID = firstCommentFromOwnersID;
                  if (this.ownerComment.length > this.limitOwnerComment)
                  {
                    this.ownerComment = `${this.ownerComment.substring(0, 500)}...`;
                  }
                } else {
                  this.ownerComment = 'No owner comments yet'
                }
              } else {
                this.ownerComment = "You don't have permission to read this comment"
              }
            }

            // Trigger the comment modal from query string
            try {
              const focusChatId = this.router.parseUrl(this.router.url).queryParams['chatId'] || '';
              const focusChartId = this.removeHiddenCharacters(focusChatId.split('[')[0])
              if (focusChartId === this.removeHiddenCharacters(this.chartId)) {
                const $ = window["jQuery"];

                setTimeout(() => {
                  if (this.focusLinkLoaded === false) {
                    const button = $(`#commentModal_${focusChartId}`);

                    if(button.parents('.tab-content'.length > 0)) {
                      const tabId = button.parents('.tab-pane').attr('id')
                      button.closest('.tab-content').siblings('.nav-tabs').find(`[aria-controls="${tabId}"]`)[0]?.click()
                    }
                    button.trigger('click');
                    this.focusLinkLoaded = true;
                  }
                }, 0);
              }
            } catch (error) {
              console.log(error)
            }

            //this.getPermissions();
        },
        error =>
        {
            console.log(error);
        }
    );
  }

  getCommentsCount() {
    return this.comments?.length ?? 0
  }

  deleteComment(cid: string) {
    this.deletecommentbody = {
      id: cid
    };
    let queryUrl = environment.hygraphDeleteComment;
    let httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
    }
    this.http.post<any>(queryUrl, this.deletecommentbody, httpOptions)
        .subscribe(
        response => {
          this.getSelfComments();
          this.updateOwnerComment();
        },
        error => console.log(error)
    );
  }

  editComment(cid: string, text: string) {
    this.isLoading = true;
    this.updatecommentbody = {
      message: this.encodeTrimmedText(text),
      id: cid
    };

    let queryUrl = environment.hygraphUpdateComment;
    let httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
    }
    this.http.post<any>(queryUrl, this.updatecommentbody, httpOptions)
        .subscribe(
        response => {
          this.getSelfComments()
          this.updateOwnerComment()
          this.editCommentId = "";
          this.editCommentText = "";

          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          console.log(error);
        }
    );
  }

  loadNonApex = (timeout: number = 0) => {
    if (this.screen) {
      this.isNonApexLoading = true;
      this.beforeScreen.emit(null);
      setTimeout(() => {
        htmlToImage.toPng(this.screen, { quality: 1.0 })
          .then((dataUrl) => {
            this.image = dataUrl
            this.afterScreen.emit(null);
            this.isNonApexLoading = false;
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
            this.isNonApexLoading = false;
          });
      }, timeout);
    }
  }

  async onOpen(content: any) {
    this.isModalOpen = true;
    this.editCommentId = "";

    // Load Chart Image
    if (this.chartRef) {
      try {
        const base64 = await this.chartRef.dataURI() as any;
        this.image = base64.imgURI;
      } catch (error) {

      }
    }

    if (this.isNonApexLoading === false) {
      this.loadNonApex()
    }

    // this.getSelfComments();
    this.modalRef = this.modalService.open(content, this.modalOptions);

    this.modalRef.dismissed.subscribe(() => {
      this.isModalOpen = false;
      this.resetCommentStatus();
    });

    if (this.isModalOpen && this.ownerCommentID?.length > 0 && this.type === 'OwnerComments') {
      this.scrollToElement(this.ownerCommentID);
    }
  }

  getFocusUrl = (chatId: string) => {
    return `${location.origin}${location.pathname}?chatId=${chatId}`
  }

  onItemSelected(item: any): void {
    if (!this.usermentions.includes(item.email))
    {
      this.usermentions.push(item.email);
    }
  }

  encodeTrimmedText(text: string) {
    return encodeURIComponent(text.trim())
  }

  onSubmit() {
    this.isLoading = true;
    let queryUrl = environment.hygraphCreateComment;
    this.commentbody = {
      chatId: this.getChatId(),
      email: localStorage.getItem('email'),
      message: this.encodeTrimmedText(this.newCommentText),
      focusUrl: this.getFocusUrl(this.getChatId())
    }
    let httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
    }
    this.http.post<any>(queryUrl, this.commentbody, httpOptions)
        .subscribe(
        response => {
          this.usermentions.forEach(x => {
            var user_email = x.slice(0, x.indexOf('@'));
            if (!this.newCommentText.includes(user_email))
            {
              return;
            }
            if (!this.receivers.some(y => y.email == x))
            {
              this.receivers.push(x);
            }
          })

          this.emailbody = {
            sender: localStorage.getItem("email"),
            receivers: this.receivers,
            message: this.newCommentText.trim(),
            page: location.pathname.replace("/", "").toUpperCase(),
            url: this.getFocusUrl(this.getChatId())
          };

          if (this.receivers.length > 0)
          {
            let queryUrl = environment.commentsendemail;
            let httpOptions: Object = {
                headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                responseType: 'text'
            }
            this.http.post<any>(queryUrl, this.emailbody, httpOptions)
                .subscribe(
                response => {
                  //do nothing
                },
                error => console.log(error)
            );
          }

          this.usermentions = [];
          this.emailbody = {};
          this.receivers = [];

          this.getSelfComments()
          this.updateOwnerComment()
          this.newCommentText = '';

          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          console.log(error)
        }
    );
  }

  validateUsers(text: string) {
    const regex = /@[\w.]+/g;
    const matches = text?.match(regex);
    let result = true;
    matches?.forEach(match => {
      if (!this.users.some(user => `@${user.name}` === match)) {
        result = false;
      }
    });
    return result;
  }

  private validate(text: string) {
    if (this.validateUsers(text)) {
      this.validationError = ''
    } else {
      this.validationError = 'You can only mention users in the list!'
      return false;
    }
    return (text?.length > 0 && text !== '@' && text.replace(/ㅤ/g, '').trim() !== '');
  }

  validateNewCommentText() {
    return this.validate(this.newCommentText)
  }

  validateNewCommentTextCancel() {
    return this.newCommentText?.length > 0 && this.newCommentText?.replace(/ㅤ/g, '').trim() !== '';
  }

  validateEditCommentText() {
    return this.editCommentText?.length > 0 && this.editCommentText?.replace(/ㅤ/g, '').trim() !== '';
  }

  clearNewCommentText() {
    return this.newCommentText = ''
  }

  clearEditCommentText() {
    return this.editCommentText = ''
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
      this.isModalOpen = false;
      this.resetCommentStatus();
    }
  }

  resetCommentStatus()
  {
    this.commentStatuses = [];
    this.comments = this.comments.map((msg) => {
      return {
        ...msg,
        message: this.wrapUsername(decodeURIComponent(msg.message)),
        rawMessage: decodeURIComponent(msg.message),
        shouldShowAll: undefined
      }
    });
  }

  scrollToElement(elementId: string) {
    if (!elementId) {
      console.error("Element ID is empty or undefined.");
      return;
    }

    const timeout = isAppleDevice() ? 100 : 0;

    setTimeout(() => {
      const targetElement = document.getElementById(elementId);

      if (targetElement) {
        try {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Modern browsers
        } catch (e) {
          targetElement.scrollIntoView(); // Fallback for older browsers
        }
      } else {
        console.error(`Element with ID "${elementId}" not found.`);
      }
    }, timeout);
  }

  public handleCommentStatus(commentStatus: any)
  {
    if (!this.commentStatuses.some(x => x.commentId == commentStatus.commentId))
    {
      this.commentStatuses.push(commentStatus);
    }
    else
    {
      let existCommentStatus = this.commentStatuses.find(x => x.commentId == commentStatus.commentId);
      existCommentStatus.shouldShowAll = commentStatus.shouldShowAll;
    }

    const { commentId, shouldShowAll } = commentStatus;

    if (!shouldShowAll && commentId.length > 0) {
      this.scrollToElement(commentId);
    }
  }
}
