import { Component, Renderer2, OnInit, OnDestroy, Inject, ViewChild, ElementRef ,Input, OnChanges, Output, EventEmitter } from '@angular/core';

import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { HQProgressHelper } from './hq_progress_helper';
import { downloadAsPDF as _downloadAsPDF } from './../../../helpers/pdf';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { dispatchDateSelectChange } from 'src/app/helpers/tracking';
import { SalesDateService } from '../../elements/sales-date-filter/sales-date-filter.service';
import { UserService } from 'src/app/services/user.services';

@Component({
  selector: 'app-hq_progress',
  templateUrl: './hq_progress.component.html',
  styleUrls: ['./hq_progress.component.scss'],
  host     : {
    'class': 'se-report-content'
  }
})
export class HQProgressComponent implements OnInit, OnDestroy {
  isDownloadLoading: boolean = false;
  private loadingSubscription: Subscription;
  private salesDateSubscription: Subscription;

  lstAllOptionsData : any;
  ishqLevel1Ready: boolean;
  ishqLevel2Ready: boolean;
  ishqTimelineReady: boolean;

  weeks: any;
  selectedWeek: string;

  fromDate: any;
  toDate: any;
  maxDate: any;
  minDate: any;
  selectedDate: any;
  lstOption_Aggregated_SalesHQLev1: any;

  constructor(private router: Router,public datepipe: DatePipe,
    private salesDateService: SalesDateService,
    private userService: UserService,
    private http: HttpClient) { }

  async ngOnInit() {
    if (!authorize()) {
      this.router.navigateByUrl('unauthorized');
    } else {
      const userGroupName = this.userService.getUserInfo() || '';
      this.salesDateSubscription = this.salesDateService.getSESalesDate().subscribe((date: string[]) => {
        if (date && date.length > 0) {
          HQProgressHelper.GetAllFilterOptions(this.http, date[0], userGroupName).then((data) => {
            this.lstAllOptionsData = data.Filters;
            IndexDbHelper.Set('hqAllOptionsData', data);
          });
        }
      });

      this.loadingSubscription = HQProgressHelper.isCsvLoading().subscribe((loading: boolean) => {
        this.isDownloadLoading = loading;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  receive_Aggregated_SalesHQLev1(selected: any){
    this.lstOption_Aggregated_SalesHQLev1 = {keyName : 'SALES_HQ_LEV1_NAME', selectedItems : selected};
  }

  public hqLevel1Ready(ready: boolean){
    this.ishqLevel1Ready = ready;
  }

  public hqLevel2Ready(ready: boolean){
    this.ishqLevel2Ready = ready;
  }

  public hqTimelineReady(ready: boolean){
    this.ishqTimelineReady = ready;
  }

  public onDateTypeSaveItemsChange(event: any) {
    const {selectedSalesDate, isTracking} = event;

    if (isTracking) {
      dispatchDateSelectChange({ ...event, ...{ element: "hq-progress" }, ...{ isDateRange: true } });
    }

    if (selectedSalesDate) {
      this.fromDate = selectedSalesDate[0];
      this.toDate = selectedSalesDate[1];
    }
  }
}
