import * as tsvParse from "d3-dsv";
import { environment } from 'src/environments/environment';
import { AnalyticsQueries } from 'src/app/model/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
export class IntageHelper 
{
    static CategoryColors(){
        return [
            {
            category: "TOTAL",
            displayName: "Total",
            color: "#8B90A3",
            index: 1
            },
            {
            category: "NS Tea",
            displayName: "NST",
            color: "#93AFB9",
            index: 2
            },
            {
            category: "Coffee",
            displayName: "Coffee",
            color: "#C69C83",
            index: 3
            },
            {
            category: "Water",
            displayName: "Water",
            color: "#4DA2D6",
            index: 4
            },
            {
            category: "Juice",
            displayName: "Juice",
            color: "#FCCF4D",
            index: 5
            },
            {
            category: "Energy",
            displayName: "Energy",
            color: "#F08258",
            index: 6
            },
            {
            category: "Flavored SSD",
            displayName: "Flavors SSD",
            color: "#9A64A7",
            index: 7
            },
            {
            category: "Black Tea",
            displayName: "Black Tea",
            color: "#A09498",
            index: 8
            },
            {
            category: "Sports",
            displayName: "Sports",
            color: "#4FB9C1",
            index: 9
            },
            {
            category: "Cola SSD",
            displayName: "Cola",
            color: "#F40009",
            index: 10
            },
            {
            category: "Others",
            displayName: "Other",
            color: "#BCC3D1",
            index: 11
            }
        ];
    }

    static CategoryColorsVM(){
      return [
          {
          category: "TOTAL",
          displayName: "Total",
          color: "#8B90A3",
          index: 1
          },
          {
          category: "NST",
          displayName: "NST",
          color: "#93AFB9",
          index: 2
          },
          {
          category: "Coffee",
          displayName: "Coffee",
          color: "#C69C83",
          index: 3
          },
          {
          category: "Water",
          displayName: "Water",
          color: "#4DA2D6",
          index: 4
          },
          {
          category: "Juice",
          displayName: "Juice",
          color: "#FCCF4D",
          index: 5
          },
          {
          category: "Energy",
          displayName: "Energy",
          color: "#F08258",
          index: 6
          },
          {
          category: "Flavors SSD",
          displayName: "Flavors SSD",
          color: "#9A64A7",
          index: 7
          },
          {
          category: "Black Tea",
          displayName: "Black Tea",
          color: "#A09498",
          index: 8
          },
          {
          category: "Sports",
          displayName: "Sports",
          color: "#4FB9C1",
          index: 9
          },
          {
          category: "Cola SSD",
          displayName: "Cola",
          color: "#F40009",
          index: 10
          },
          {
          category: "Other",
          displayName: "Other",
          color: "#BCC3D1",
          index: 11
          }
      ];
  }
    static CategoryColorsOTCByRegion(){
        return [
            {
            category: "TOTAL",
            displayName: "Total",
            color: "#8B90A3",
            index: 1
            },
            {
            category: "Coffee",
            displayName: "Coffee",
            color: "#C69C83",
            index: 2
            },
            {
            category: "NS Tea",
            displayName: "NST",
            color: "#93AFB9",
            index: 3
            },
            {
            category: "Water",
            displayName: "Water",
            color: "#4DA2D6",
            index: 4
            },
            {
            category: "Juice",
            displayName: "Juice",
            color: "#FCCF4D",
            index: 10
            },
            {
            category: "Flavored SSD",
            displayName: "Flavors SSD",
            color: "#9A64A7",
            index: 7
            },
            {
            category: "Sports",
            displayName: "Sports",
            color: "#4FB9C1",
            index: 6
            },
            {
            category: "Energy",
            displayName: "Energy",
            color: "#F08258",
            index: 5
            },
            {
            category: "Cola SSD",
            displayName: "Cola",
            color: "#F40009",
            index: 9
            },
            {
            category: "Black Tea",
            displayName: "Black Tea",
            color: "#A09498",
            index: 8
            },
            {
            category: "Others",
            displayName: "Other",
            color: "#BCC3D1",
            index: 11
            }
        ];
    }

    static CategoryColorsOTCByRegionVM(){
      return [
          {
          category: "TOTAL",
          displayName: "Total",
          color: "#8B90A3",
          index: 1
          },
          {
          category: "Coffee",
          displayName: "Coffee",
          color: "#C69C83",
          index: 2
          },
          {
          category: "NST",
          displayName: "NST",
          color: "#93AFB9",
          index: 3
          },
          {
          category: "Water",
          displayName: "Water",
          color: "#4DA2D6",
          index: 4
          },
          {
          category: "Juice",
          displayName: "Juice",
          color: "#FCCF4D",
          index: 10
          },
          {
          category: "Flavors SSD",
          displayName: "Flavors SSD",
          color: "#9A64A7",
          index: 7
          },
          {
          category: "Sports",
          displayName: "Sports",
          color: "#4FB9C1",
          index: 6
          },
          {
          category: "Energy",
          displayName: "Energy",
          color: "#F08258",
          index: 5
          },
          {
          category: "Cola SSD",
          displayName: "Cola",
          color: "#F40009",
          index: 9
          },
          {
          category: "Black Tea",
          displayName: "Black Tea",
          color: "#A09498",
          index: 8
          },
          {
          category: "Other",
          displayName: "Other",
          color: "#BCC3D1",
          index: 11
          }
      ];
  }
    static RegionOrders(){
      return [
        {
          region: "NORTH KANTO/SOUTH TOHOKU",
        index: 1
        },
        {
          region: "METROPOLITAN",
        index: 2
        },
        {
          region: "KANSAI/CHUBU",
        index: 3
        },
        {
          region: "KYUSHU/CHU-SHIKOKU",
        index: 4
        }
      ];
    }
    static ChannelOrders(){
        return [
            {
            channel: "SM",
            index: 1
            },
            {
            channel: "Drug",
            index: 2
            },
            {
            channel: "Discounter",
            index: 3
            },
            {
            channel: "CVS",
            index: 4
            },
            {
            channel: "Other OTC",
            index: 5
            }
        ];
    }

    static NewRegionOrders(){
      return [
        {
          region: "East Japan",
          index: 1
        },
        {
          region: "Kanto",
          index: 2
        },
        {
          region: "Tokyo",
          index: 3
        },
        {
          region: "Central Japan",
          index: 4
        }
        ,
        {
          region: "Kinki",
          index: 5
        }
        ,
        {
          region: "Chu-Shikoku",
          index: 6
        }
        ,
        {
          region: "Kyushu",
          index: 7
        }
      ];
  }

    static GetIntageVMData(http: HttpClient) : Promise<any>{
      return new Promise<any>((resolve, reject) => {
              let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.intageVMAllData;
              let httpOptions: Object = {
                  headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                  responseType: 'text'
              }
              let t=this;
              http.get<any>(queryUrl, httpOptions)
                  .subscribe(
                  response => {
                      let intage = tsvParse.tsvParse('week	botter_sci	category	maker	agg	tyvalue	tyunit	lyvalue	lyunit\n' + response);
                      resolve(intage);
                  },
                  error =>
                  {
                    console.log(error);
                  }
                  );
      });
  }

  static GetIntageVMWeekData(http: HttpClient) : Promise<any>{
    return new Promise<any>((resolve, reject) => {
            let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.intageVMWeekData;
            let httpOptions: Object = {
                headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                responseType: 'text'
            }
            let t=this;
            http.get<any>(queryUrl, httpOptions)
                .subscribe(
                response => {
                  let intageweek = tsvParse.tsvParse('week\n' + response);
                  resolve(intageweek);
                },
                error => 
                {
                  console.log(error);
                }
                );
            
    });
  }

  static otcCompare(a, b) {
        if (a.categoryIndex < b.categoryIndex){
        return -1;
        }
        if (a.categoryIndex > b.categoryIndex){
        return 1;
        }
        if (a.channelIndex < b.channelIndex){
        return -1;
        }
        if (a.channelIndex > b.channelIndex){
        return 1;
        }
        if (a.regionIndex < b.regionIndex){
        return -1;
        }
        if (a.regionIndex > b.regionIndex){
        return 1;
        }
        return 0;
    }

    static MakerLogos(){
        return [
            {
              maker : "COCA-COLA",
              logo: "assets/img/maker/Coca-Cola.png",
              index: 1,
              checked: true
            },
            {
              maker : "SUNTORY",
              logo: "assets/img/maker/Suntory.png",
              index: 2,
              checked: false
            },
            {
              maker : "KIRIN",
              logo: "assets/img/maker/Kirin.png",
              index: 3,
              checked: false
            },
            {
              maker : "ASAHI",
              logo: "assets/img/maker/Asahi.png",
              index: 4,
              checked: false
            },
            {
              maker : "ITOEN",
              logo: "assets/img/maker/ITO_EN_logo_jp.png",
              index: 5,
              checked: false
            },
            {
              maker : "DYDO",
              logo: "assets/img/maker/DyDo.png",
              index: 4,
              checked: false
            }            
          ];
    }

    static MakerLogosVM(){
      return [
          {
            maker : "COCA-COLA",
            logo: "assets/img/maker/Coca-Cola.png",
            index: 1,
            checked: true
          },
          {
            maker : "Suntory",
            logo: "assets/img/maker/Suntory.png",
            index: 2,
            checked: false
          },
          {
            maker : "Kirin",
            logo: "assets/img/maker/Kirin.png",
            index: 3,
            checked: false
          },
          {
            maker : "Asahi",
            logo: "assets/img/maker/Asahi.png",
            index: 4,
            checked: false
          },
          {
            maker : "Itoen",
            logo: "assets/img/maker/Itoen.png",
            index: 5,
            checked: false
          },
          {
            maker : "Dydo",
            logo: "assets/img/maker/DyDo.png",
            index: 4,
            checked: false
          }
        ];
  }

    static SmddChannels(){
        return [
            {
              channel: "All",
              index: 7,
              child: false
            },
            {
              channel: "SMDD",
              index: 1,
              childChannels: [
                "SM",
                "Drug",
                "Discounter"
              ],
              child: false
            },
            {
              channel: "SM",
              index: 2,
              child: true
            },
            {
              channel: "Drug",
              index: 3,
              child: true
            },
            {
              channel:  "Discounter",
              index: 4,
              child: true
            },
            {
              channel: "CVS",
              index: 5,
              child: false
            },
            {
              channel: "Other OTC",
              index: 6,
              child: false
            }
          ];
    }

    static PopulateCommonData(intage: any, selectedWeek: string){
        let otcData = [];
        let selectedWeekInt = parseInt(selectedWeek.replace('-','').replace('-',''));
        for(var i = 0;i<intage.length;i++){
            let row = intage[i];
            if(parseInt(row.week.replace('-','').replace('-','')) > selectedWeekInt){
                continue;
            }
            else {
                otcData.push(row);
            }
        }

        let categories = [{
            category: 'TOTAL'
          }
        ];
        let summaryRegions = [];
        let makers = [];
        let summaryPackageTypes = [];
        let categoriesFilter = [];
        for(var i=0;i<intage.length;i++){
            let row = intage[i];
            if(categories.find(x=> x.category == row.category) == null){
              categories.push({
                category: row.category
              });
            }
            var summaryRegion = summaryRegions.find(x=> x == row.region);
            if(summaryRegion != null){
              summaryRegions.push(row.region);
            }
            let maker = makers.find(x=> x.maker == row.maker);
            if(maker == null && row.maker != 'TOTAL'){
                let makerLogo = this.MakerLogos().find(x=> x.maker == row.maker);
                makers.push({
                    maker: row.maker,
                    index: makerLogo != null ? makerLogo.index: 100
                });
            }
            if(row.package == ''){
              continue;
            }
            var summaryPackageType = summaryPackageTypes.find(x=> x == row.package);
            if(summaryPackageType == null){
              summaryPackageTypes.push(row.package);
            }
        }
        categoriesFilter = categories.slice();
        makers.sort((a, b) => a.index > b.index ? 1 : -1);
        return {
            categories: categories,
            summaryRegions: summaryRegions,
            makers: makers,
            summaryPackageTypes: summaryPackageTypes,
            categoriesFilter: categoriesFilter
        };
    }

    static GetCategoryColor(category: string){
        let cate = this.CategoryColorsVM().find(x=> x.category == category);
        if(cate == null){
          return "#8B90A3";
        }
        return cate.color;
    }

    static GetCategoryDisplayName(category: string){
        let cate = this.CategoryColors().find(x=> x.category == category);
        if(cate == null){
          return category;
        }
        return cate.displayName;
    }

    static GetShareOfCategory(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "0.0";
      }
      return totalCategory.share;
    }

    static GetVsLyOfCategoryOTCMarket(marketShareData2: any, maker: string, category: string){
      if (marketShareData2.filter(x => x.maker == maker && x.category == category)[0]?.diff_ly == null)
      {
        return '-';
      }
      return marketShareData2.filter(x => x.maker == maker && x.category == category)[0]?.diff_ly;
    }
    static GetVsLyVolumeOfCategory(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "0.0";
      }
      
      return totalCategory.vsLyVolume;
    }
    static GetVsLyVolumeOfCategoryForMarketSize(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "-100.0";
      }
      
      return totalCategory.vsLyVolume;
    }
    //FPT-TruongDV19 Update default value for null case.
    static GetVsLyOfCategoryForMarketSize(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "-100.0";
      }
      
      return totalCategory.vsLy;
    }

    static GetImpOfCategory(categories: any, category: string){
      let totalCategory = categories.find(x=> x.category == category);
      if(totalCategory == null){
        return "0.0";
      }
      return totalCategory.imp;
    }

    static GetIntageMonthFromWeek(http: HttpClient, weeklist) : Promise<any>{
      return new Promise<any>((resolve, reject) => {
              let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.intageMonthFromWeek + "&weeklist=" + weeklist;
              let httpOptions: Object = {
                  headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                  responseType: 'text'
              }
              let t=this;
              http.get<any>(queryUrl, httpOptions)
                  .subscribe(
                  response => {
                      let tyPerWeek = [];
                      let intageMonth = tsvParse.tsvParse('week	month	quarter	year\n' + response);
                      resolve(intageMonth);
                  },
                  error =>
                  {
                    console.log(error);
                  }
                  );                
          });
      }
      
      static transformData(data: any)
      {
        data.map(x => {
          x.tyvalue = parseFloat(x.tyvalue);
          x.lyvalue = parseFloat(x.lyvalue);
          x.tyunit = parseFloat(x.tyunit);
          x.lyunit = parseFloat(x.lyunit);
        })
        return data;
      }

      static categoriesFilter()
      {
        return ['Total', 'Coffee', 'NST', 'Water', 'Energy', 'Sports', 'Flavors SSD', 'Black Tea', 'Cola SSD', 'Juice', 'Other'];
      }

      static district()
      {
        return ['CCBJI', 'East Japan', 'Kanto', 'Tokyo', 'Central Japan', 'Kinki', 'Chugoku-Shikoku', 'Kyushu'];
      }

      static formatCategoryName(categoryName: any)
      {
        if (categoryName == 'Cola SSD')
        {
          return 'Cola';
        }
        return categoryName;
      }

      static formatCategorySankeySummary(result: any)
      {
        result.forEach(arr => {
          let colaIndex = arr.indexOf('Cola SSD');
          if (colaIndex >= 0)
          {
            arr[colaIndex] = 'Cola';
          }
        })
        return result;
      }

      static formatIntageNumber(number: any)
      {
          if (isNaN(number) || number == null)
          {
              return null;
          }
          return number > 0 ? (Math.floor(number * 10 + 0.5) / 10) : (Math.ceil(number * 10 - 0.5) / 10);
      }

      static formatIntageSankeyNumber(number: any)
      {
          if (isNaN(number) || number == null)
          {
              return null;
          }
          return number > 0 ? Math.floor(number + 0.5) : Math.ceil(number - 0.5);
      }

      static formatIntageScorecardNumber(number: any)
      {
          if (isNaN(number) || number == null)
          {
              return '-';
          }
          return number > 0 ? (Math.floor(number * 10 + 0.5) / 10) : (Math.ceil(number * 10 - 0.5) / 10);
      }

      static formatValueCsv(number: any)
      {
          if (isNaN(number) || number == null)
          {
              return '-';
          }
          return number;
      }
} 
