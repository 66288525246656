import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss']
})
export class ShowMoreComponent implements OnInit {
  @Input() text: string;
  @Input() commentId: string;
  @Input() limit: number = 500;
  @Input() shouldShowAll: boolean = false;
  @Output() commentStatus = new EventEmitter<any>();
  shouldShowSeeLess = false;

  getShortenText(): string {
    return this.text?.substring(0, this.limit);
  }

  showAll(shouldShowAll, event): void {
    event.stopPropagation();
    this.shouldShowAll = shouldShowAll;
    this.commentStatus.emit(
      {
        commentId: this.commentId,
        shouldShowAll: shouldShowAll
      }
    )
  }

  ngOnChanges(changes: OnChanges) {
    if (typeof this.shouldShowAll === 'undefined')
    {
      this.shouldShowAll = this.text.length <= this.limit;
    }
    
    this.shouldShowSeeLess = this.text.length >= this.limit;
  }

  ngOnInit() {       
  }
}
